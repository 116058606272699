import { Keys } from '../types';

const keys: Keys = {
  infura: 'daaa68ec242643719749dd1caba2fc66',
  alchemy: '',
  graph: 'a84caa9e5c322a2faec24ad89ccb9d28',
  balancerApi: 'da2-7a3ukmnw7bexndpx5x522uafui',
};

export default keys;
